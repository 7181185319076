.wrapper {
  position: relative;
  top: -100px;
  width: 100%;
}
.waves {
  position: relative;
  width: 100%;
  margin-bottom: -7px; /*Fix for safari gap*/
  height: 100px;
  min-height: 100px;
}
/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 20s;
}
.parallax > use:nth-child(2) {
  animation-delay: -6s;
  animation-duration: 24s;
}
.parallax > use:nth-child(3) {
  animation-delay: -8s;
  animation-duration: 28s;
}
.parallax > use:nth-child(4) {
  animation-delay: -10s;
  animation-duration: 32s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 1000px) {
  .wrapper {
    top: -50px;
  }
  .waves {
    height: 70px;
    min-height: 70px;
  }
}
@media (max-width: 800px) {
  .wrapper {
    top: -50px;
  }
  .waves {
    height: 50px;
    min-height: 50px;
  }
}

